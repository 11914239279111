import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  Background: Color.Primitive.Primary,
  IconAndTextColor: Color.Primitive.PrimaryContrast,

  // This is not a mistake, we use the foreground as background
  RightCornerBackground: Color.Surface.Base.Foreground,
  RightCornerIconAndTextColor: Color.Surface.Base.Background,
};
